export function formatDate(dateString: string): string {
  // Handle relative time strings (e.g., "2時間前")
  if (dateString.includes('時間前') || dateString.includes('日前')) {
    return dateString;
  }

  // Handle ISO date strings
  const date = new Date(dateString);
  const now = new Date();
  const diffInHours = Math.floor((now.getTime() - date.getTime()) / (1000 * 60 * 60));

  if (diffInHours < 24) {
    return `${diffInHours}時間前`;
  }

  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays < 30) {
    return `${diffInDays}日前`;
  }

  // Format as YYYY年MM月DD日
  return date.toLocaleDateString('ja-JP', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
}