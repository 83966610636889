import React from 'react';
import { Scale } from 'lucide-react';

export default function LegalPage() {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center mb-6">
          <Scale className="h-6 w-6 text-red-600 mr-2" />
          <h1 className="text-2xl font-bold">特定商取引法に基づく表記</h1>
        </div>

        <div className="prose max-w-none">
          <div className="space-y-8">
            <div>
              <h2 className="text-lg font-semibold bg-gray-50 p-2">運営者</h2>
              <p className="p-4">山田 太郎</p>
            </div>

            <div>
              <h2 className="text-lg font-semibold bg-gray-50 p-2">所在地</h2>
              <p className="p-4">
                〒000-0000<br />
                東京都渋谷区XX町1-1-1
              </p>
            </div>

            <div>
              <h2 className="text-lg font-semibold bg-gray-50 p-2">お問い合わせ先</h2>
              <div className="p-4">
                <p>メールアドレス：support@otokuzu.jp</p>
                <p>※お問い合わせはメールのみで承っております</p>
                <p>※ご返信は2営業日以内を目標としておりますが、状況により遅れる場合がございます</p>
              </div>
            </div>

            <div>
              <h2 className="text-lg font-semibold bg-gray-50 p-2">サービスの内容</h2>
              <p className="p-4">
                お得な商品情報の共有プラットフォームの運営
              </p>
            </div>

            <div>
              <h2 className="text-lg font-semibold bg-gray-50 p-2">利用料金</h2>
              <div className="p-4">
                <p>サービスの利用料：無料</p>
                <p>※ただし、インターネット接続料金はお客様のご負担となります。</p>
              </div>
            </div>

            <div>
              <h2 className="text-lg font-semibold bg-gray-50 p-2">免責事項</h2>
              <div className="p-4">
                <ul className="list-disc list-inside space-y-2">
                  <li>当サイトに掲載される情報の正確性については万全を期しておりますが、その内容を保証するものではありません。</li>
                  <li>掲載された情報により生じたいかなる損害についても、当サイトは一切の責任を負いません。</li>
                  <li>各商品の価格、在庫、セール情報等は、予告なく変更される場合があります。</li>
                  <li>当サイトは、各商品の販売を行うものではなく、商品情報の共有のみを目的としています。</li>
                </ul>
              </div>
            </div>
          </div>

          <p className="text-gray-500 mt-8 text-sm">
            最終更新日：2024年1月1日
          </p>
        </div>
      </div>
    </div>
  );
}