import React, { useState } from 'react';
import { Mail, Building2, User, MessageSquare, AlertCircle } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';

type InquiryType = 
  | 'general' 
  | 'account' 
  | 'report' 
  | 'advertisement' 
  | 'business' 
  | 'press' 
  | 'other';

interface FormData {
  type: InquiryType;
  name: string;
  email: string;
  company?: string;
  department?: string;
  phone?: string;
  subject: string;
  message: string;
  urgency: 'low' | 'medium' | 'high';
}

export default function ContactPage() {
  const { user } = useAuth();
  const [formData, setFormData] = useState<FormData>({
    type: 'general',
    name: user?.username || '',
    email: user?.email || '',
    subject: '',
    message: '',
    urgency: 'medium'
  });
  const [showBusinessFields, setShowBusinessFields] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  const inquiryTypes = [
    { value: 'general', label: '一般的なお問い合わせ' },
    { value: 'account', label: 'アカウントについて' },
    { value: 'report', label: '不適切な投稿の報告' },
    { value: 'advertisement', label: '広告掲載について' },
    { value: 'business', label: 'ビジネス提携のご相談' },
    { value: 'press', label: '取材・プレスリリース' },
    { value: 'other', label: 'その他' }
  ];

  const handleTypeChange = (type: InquiryType) => {
    setFormData(prev => ({ ...prev, type }));
    setShowBusinessFields(['advertisement', 'business', 'press'].includes(type));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    try {
      // In a real app, this would be an API call
      console.log('Form submitted:', formData);
      setSubmitted(true);
      
      // Reset form after successful submission
      setFormData({
        type: 'general',
        name: user?.username || '',
        email: user?.email || '',
        subject: '',
        message: '',
        urgency: 'medium'
      });
      setShowBusinessFields(false);
    } catch (err) {
      setError('お問い合わせの送信に失敗しました。もう一度お試しください。');
    }
  };

  if (submitted) {
    return (
      <div className="max-w-3xl mx-auto px-4 py-8">
        <div className="bg-white rounded-lg shadow-sm p-8 text-center">
          <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
            <Mail className="h-8 w-8 text-green-600" />
          </div>
          <h2 className="text-2xl font-bold mb-2">お問い合わせありがとうございます</h2>
          <p className="text-gray-600 mb-6">
            内容を確認の上、担当者より順次ご連絡させていただきます。
            通常2営業日以内にご返信いたしますが、内容によってはお時間をいただく場合がございます。
          </p>
          <button
            onClick={() => setSubmitted(false)}
            className="px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
          >
            新しいお問い合わせを作成
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center mb-6">
          <MessageSquare className="h-6 w-6 text-red-600 mr-2" />
          <h1 className="text-2xl font-bold">お問い合わせ</h1>
        </div>

        {error && (
          <div className="mb-6 p-4 bg-red-50 text-red-600 rounded-lg flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* お問い合わせ種別 */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              お問い合わせ種別
            </label>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
              {inquiryTypes.map(type => (
                <label
                  key={type.value}
                  className={`flex items-center p-3 border rounded-lg cursor-pointer transition-colors ${
                    formData.type === type.value
                      ? 'border-red-600 bg-red-50'
                      : 'border-gray-200 hover:bg-gray-50'
                  }`}
                >
                  <input
                    type="radio"
                    name="type"
                    value={type.value}
                    checked={formData.type === type.value}
                    onChange={(e) => handleTypeChange(e.target.value as InquiryType)}
                    className="sr-only"
                  />
                  <span className={`text-sm ${
                    formData.type === type.value ? 'text-red-600' : 'text-gray-700'
                  }`}>
                    {type.label}
                  </span>
                </label>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* お名前 */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                お名前 <span className="text-red-600">*</span>
              </label>
              <div className="relative">
                <User className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
                <input
                  type="text"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-red-500"
                  required
                />
              </div>
            </div>

            {/* メールアドレス */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                メールアドレス <span className="text-red-600">*</span>
              </label>
              <div className="relative">
                <Mail className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
                <input
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-red-500"
                  required
                />
              </div>
            </div>
          </div>

          {/* 法人向けフィールド */}
          {showBusinessFields && (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  会社名 <span className="text-red-600">*</span>
                </label>
                <div className="relative">
                  <Building2 className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
                  <input
                    type="text"
                    value={formData.company}
                    onChange={(e) => setFormData({ ...formData, company: e.target.value })}
                    className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-red-500"
                    required={showBusinessFields}
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  部署名
                </label>
                <input
                  type="text"
                  value={formData.department}
                  onChange={(e) => setFormData({ ...formData, department: e.target.value })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-red-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  電話番号
                </label>
                <input
                  type="tel"
                  value={formData.phone}
                  onChange={(e) => setFormData({ ...formData, phone: e.target.value })}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-red-500"
                  placeholder="例：03-1234-5678"
                />
              </div>
            </div>
          )}

          {/* 件名 */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              件名 <span className="text-red-600">*</span>
            </label>
            <input
              type="text"
              value={formData.subject}
              onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-red-500"
              required
            />
          </div>

          {/* お問い合わせ内容 */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              お問い合わせ内容 <span className="text-red-600">*</span>
            </label>
            <textarea
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
              className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-1 focus:ring-red-500"
              rows={6}
              required
            />
          </div>

          {/* 緊急度 */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              緊急度
            </label>
            <div className="flex space-x-4">
              {[
                { value: 'low', label: '低' },
                { value: 'medium', label: '中' },
                { value: 'high', label: '高' }
              ].map(option => (
                <label
                  key={option.value}
                  className={`flex items-center p-3 border rounded-lg cursor-pointer transition-colors ${
                    formData.urgency === option.value
                      ? 'border-red-600 bg-red-50'
                      : 'border-gray-200 hover:bg-gray-50'
                  }`}
                >
                  <input
                    type="radio"
                    name="urgency"
                    value={option.value}
                    checked={formData.urgency === option.value}
                    onChange={(e) => setFormData({ ...formData, urgency: e.target.value as 'low' | 'medium' | 'high' })}
                    className="sr-only"
                  />
                  <span className={`text-sm ${
                    formData.urgency === option.value ? 'text-red-600' : 'text-gray-700'
                  }`}>
                    {option.label}
                  </span>
                </label>
              ))}
            </div>
          </div>

          {/* プライバシーポリシー同意 */}
          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id="privacy"
                type="checkbox"
                required
                className="h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300 rounded"
              />
            </div>
            <div className="ml-3">
              <label htmlFor="privacy" className="text-sm text-gray-600">
                <a href="/privacy" className="text-red-600 hover:text-red-700">プライバシーポリシー</a>
                に同意します
              </label>
            </div>
          </div>

          {/* 送信ボタン */}
          <div>
            <button
              type="submit"
              className="w-full px-6 py-3 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
            >
              送信する
            </button>
          </div>

          {/* 注意事項 */}
          <div className="text-sm text-gray-500">
            <p>※ 営業時間内のお問い合わせについては、通常2営業日以内にご返信いたします。</p>
            <p>※ お急ぎの場合は、緊急度を「高」に設定してください。</p>
            <p>※ 添付ファイルが必要な場合は、返信メールにてお送りください。</p>
          </div>
        </form>
      </div>
    </div>
  );
}