import React from 'react';
import { Info, Tag, Users, Zap, Shield, Heart } from 'lucide-react';

export default function AboutPage() {
  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center mb-6">
          <Info className="h-6 w-6 text-red-600 mr-2" />
          <h1 className="text-2xl font-bold">サービス概要</h1>
        </div>

        <div className="prose max-w-none">
          <div className="mb-12">
            <h2 className="text-xl font-semibold mb-4">お得ズとは</h2>
            <p className="text-gray-600 mb-6">
              お得ズは、ユーザーの皆様が見つけたお得な商品情報を共有するコミュニティプラットフォームです。
              セール情報、割引クーポン、タイムセールなど、様々なお買い得情報をリアルタイムで共有し、
              賢いショッピングをサポートします。
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-12">
            <div className="bg-gray-50 p-6 rounded-lg">
              <div className="flex items-center mb-4">
                <Tag className="h-5 w-5 text-red-600 mr-2" />
                <h3 className="text-lg font-semibold">厳選された情報</h3>
              </div>
              <p className="text-gray-600">
                自動収集ではなく、実際にユーザーが見つけた本当にお得な情報のみを掲載。
                信頼性の高い情報を提供します。
              </p>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg">
              <div className="flex items-center mb-4">
                <Users className="h-5 w-5 text-red-600 mr-2" />
                <h3 className="text-lg font-semibold">コミュニティ</h3>
              </div>
              <p className="text-gray-600">
                ユーザー同士で情報を共有し、コメントやいいねで評価。
                より良い買い物の判断材料を提供します。
              </p>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg">
              <div className="flex items-center mb-4">
                <Zap className="h-5 w-5 text-red-600 mr-2" />
                <h3 className="text-lg font-semibold">リアルタイム更新</h3>
              </div>
              <p className="text-gray-600">
                タイムセールやクーポン情報をリアルタイムで共有。
                見逃しのない、スピーディーな情報提供を実現します。
              </p>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg">
              <div className="flex items-center mb-4">
                <Shield className="h-5 w-5 text-red-600 mr-2" />
                <h3 className="text-lg font-semibold">安心・安全</h3>
              </div>
              <p className="text-gray-600">
                不適切な投稿は速やかに対応。
                安心してご利用いただける環境を維持します。
              </p>
            </div>
          </div>

          <div className="mb-12">
            <h2 className="text-xl font-semibold mb-4">ご利用方法</h2>
            <div className="space-y-4">
              <div className="flex items-start">
                <div className="bg-red-100 rounded-full p-2 mr-4 mt-1">
                  <span className="text-red-600 font-bold">1</span>
                </div>
                <div>
                  <h3 className="font-semibold mb-2">会員登録（無料）</h3>
                  <p className="text-gray-600">
                    メールアドレスで簡単に登録できます。
                    登録しなくても閲覧は可能ですが、投稿やお気に入り機能を使うには登録が必要です。
                  </p>
                </div>
              </div>

              <div className="flex items-start">
                <div className="bg-red-100 rounded-full p-2 mr-4 mt-1">
                  <span className="text-red-600 font-bold">2</span>
                </div>
                <div>
                  <h3 className="font-semibold mb-2">お得情報をチェック</h3>
                  <p className="text-gray-600">
                    カテゴリーやキーワードで検索して、欲しい商品のお得な情報を見つけましょう。
                    「トレンド入り」や「新着順」で並び替えることもできます。
                  </p>
                </div>
              </div>

              <div className="flex items-start">
                <div className="bg-red-100 rounded-full p-2 mr-4 mt-1">
                  <span className="text-red-600 font-bold">3</span>
                </div>
                <div>
                  <h3 className="font-semibold mb-2">情報を共有</h3>
                  <p className="text-gray-600">
                    お得な情報を見つけたら、ぜひ共有してください。
                    写真や詳細な説明を添えることで、より多くの人の役に立つ情報になります。
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-red-50 p-6 rounded-lg">
            <div className="flex items-center mb-4">
              <Heart className="h-5 w-5 text-red-600 mr-2" />
              <h2 className="text-xl font-semibold">お得ズの想い</h2>
            </div>
            <p className="text-gray-600">
              私たちは、「みんなで作る、みんなのためのお得情報」をモットーに、
              ユーザーの皆様と共に成長するプラットフォームを目指しています。
              一人一人の小さな発見が、誰かの大きな喜びになる。
              そんな価値ある情報の共有の場として、お得ズは在り続けたいと考えています。
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}