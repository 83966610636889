import type { Deal } from '../types';

export function getRecommendedDeals(
  currentDeal: Deal,
  allDeals: Deal[],
  limit: number = 5
): Deal[] {
  if (!Array.isArray(allDeals) || allDeals.length === 0) {
    return [];
  }

  const otherDeals = allDeals.filter(deal => deal.id !== currentDeal.id);
  const scores: { deal: Deal; score: number }[] = otherDeals.map(deal => ({
    deal,
    score: calculateRecommendationScore(currentDeal, deal)
  }));

  return scores
    .sort((a, b) => b.score - a.score)
    .slice(0, limit)
    .map(score => score.deal);
}

function calculateRecommendationScore(currentDeal: Deal, candidateDeal: Deal): number {
  let score = 0;

  // カテゴリーマッチング (最大 40 ポイント)
  if (currentDeal.category === candidateDeal.category) {
    score += 40;
  }

  // 価格帯マッチング (最大 30 ポイント)
  const priceDifference = Math.abs(currentDeal.price - candidateDeal.price);
  const priceRatio = Math.min(currentDeal.price, candidateDeal.price) / Math.max(currentDeal.price, candidateDeal.price);
  if (priceRatio > 0.7) {
    score += 30;
  } else if (priceRatio > 0.5) {
    score += 20;
  } else if (priceRatio > 0.3) {
    score += 10;
  }

  // 同じストア (最大 20 ポイント)
  if (currentDeal.merchant === candidateDeal.merchant) {
    score += 20;
  }

  // 人気度 (最大 15 ポイント)
  const popularityScore = Math.min(candidateDeal.votes, 1000) / 1000 * 15;
  score += popularityScore;

  // 新着順 (最大 10 ポイント)
  const hoursSincePosted = getHoursSincePosted(candidateDeal.posted);
  if (hoursSincePosted <= 24) {
    score += 10;
  } else if (hoursSincePosted <= 72) {
    score += 5;
  }

  return score;
}

function getHoursSincePosted(postedTime: string): number {
  const number = parseInt(postedTime);
  if (postedTime.includes('時間')) {
    return number;
  } else if (postedTime.includes('日')) {
    return number * 24;
  }
  return 999;
}