import React from 'react';
import { ArrowBigUp, ArrowBigDown } from 'lucide-react';
import { useVote } from '../hooks/useVoteContext';

interface VoteButtonsProps {
  dealId: string;
  initialScore?: number;
  size?: 'sm' | 'md' | 'lg';
  orientation?: 'horizontal' | 'vertical';
}

export default function VoteButtons({ 
  dealId,
  initialScore = 0,
  size = 'md',
  orientation = 'horizontal'
}: VoteButtonsProps) {
  const { score, userVote, handleVote } = useVote(dealId);

  const getScoreColor = (score: number) => {
    if (score >= 100) return 'text-red-600';
    if (score > 0) return 'text-orange-500';
    return 'text-blue-600';
  };

  const sizeClasses = {
    sm: 'h-4 w-4',
    md: 'h-5 w-5',
    lg: 'h-6 w-6'
  };

  const containerClasses = orientation === 'horizontal' 
    ? 'flex items-center space-x-1'
    : 'flex flex-col items-center space-y-1';

  return (
    <div className={containerClasses}>
      <button
        onClick={() => handleVote(userVote === 'up' ? null : 'up')}
        className={`p-1 rounded-full transition-colors ${
          userVote === 'up'
            ? 'text-red-600 bg-red-50'
            : 'text-gray-400 hover:text-red-600 hover:bg-red-50'
        }`}
        aria-label="upvote"
      >
        <ArrowBigUp className={sizeClasses[size]} />
      </button>
      
      <div className={`bg-white rounded-full px-2 py-0.5 border border-gray-200 ${getScoreColor(score)}`}>
        <span className="text-sm font-medium">{score}</span>
      </div>
      
      <button
        onClick={() => handleVote(userVote === 'down' ? null : 'down')}
        className={`p-1 rounded-full transition-colors ${
          userVote === 'down'
            ? 'text-blue-600 bg-blue-50'
            : 'text-gray-400 hover:text-blue-600 hover:bg-blue-50'
        }`}
        aria-label="downvote"
      >
        <ArrowBigDown className={sizeClasses[size]} />
      </button>
    </div>
  );
}