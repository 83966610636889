import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';
import { useNotifications } from './useNotifications';
import type { Comment, ReactionType } from '../types';

export function useComments(dealId: string) {
  const [comments, setComments] = useState<Comment[]>(() => {
    const saved = localStorage.getItem(`comments_${dealId}`);
    return saved ? JSON.parse(saved) : [];
  });
  const { user } = useAuth();
  const { addNotification } = useNotifications();

  useEffect(() => {
    localStorage.setItem(`comments_${dealId}`, JSON.stringify(comments));
  }, [comments, dealId]);

  const findCommentById = (comments: Comment[], id: string): Comment | null => {
    for (const comment of comments) {
      if (comment.id === id) return comment;
      if (comment.replies) {
        const found = findCommentById(comment.replies, id);
        if (found) return found;
      }
    }
    return null;
  };

  const updateCommentReplies = (comments: Comment[], parentId: string, newReply: Comment): Comment[] => {
    return comments.map(comment => {
      if (comment.id === parentId) {
        return {
          ...comment,
          replies: [...(comment.replies || []), newReply]
        };
      }
      if (comment.replies) {
        return {
          ...comment,
          replies: updateCommentReplies(comment.replies, parentId, newReply)
        };
      }
      return comment;
    });
  };

  const updateCommentReactions = (comments: Comment[], commentId: string, updateFn: (comment: Comment) => Comment): Comment[] => {
    return comments.map(comment => {
      if (comment.id === commentId) {
        return updateFn(comment);
      }
      if (comment.replies) {
        return {
          ...comment,
          replies: updateCommentReactions(comment.replies, commentId, updateFn)
        };
      }
      return comment;
    });
  };

  const addComment = (content: string) => {
    if (!user) return;

    const newComment: Comment = {
      id: Date.now().toString(),
      dealId,
      userId: user.id,
      username: user.username,
      userAvatar: user.avatar,
      content,
      createdAt: new Date().toISOString(),
      reactions: {
        like: [],
        laugh: [],
        helpful: []
      }
    };

    setComments(prev => [newComment, ...prev]);
  };

  const addReply = (parentId: string, content: string) => {
    if (!user) return;

    const newReply: Comment = {
      id: Date.now().toString(),
      dealId,
      userId: user.id,
      username: user.username,
      userAvatar: user.avatar,
      content,
      parentId,
      createdAt: new Date().toISOString(),
      reactions: {
        like: [],
        laugh: [],
        helpful: []
      }
    };

    setComments(prev => {
      const parentComment = findCommentById(prev, parentId);
      if (parentComment && parentComment.userId !== user.id) {
        addNotification({
          type: 'reply',
          dealId,
          commentId: newReply.id,
          userId: parentComment.userId,
          creatorId: user.id,
          title: 'コメントに返信がありました',
          message: `${user.username}さんがあなたのコメントに返信しました`
        });
      }
      return updateCommentReplies(prev, parentId, newReply);
    });
  };

  const addReaction = (commentId: string, type: ReactionType) => {
    if (!user) return;

    setComments(prev => {
      const targetComment = findCommentById(prev, commentId);
      if (!targetComment) return prev;

      return updateCommentReactions(prev, commentId, (comment) => {
        const existingReactionIndex = comment.reactions[type].findIndex(
          r => r.userId === user.id
        );

        let updatedReactions = { ...comment.reactions };

        if (existingReactionIndex >= 0) {
          updatedReactions[type] = updatedReactions[type].filter(
            r => r.userId !== user.id
          );
        } else {
          updatedReactions[type] = [
            ...updatedReactions[type],
            { type, userId: user.id, username: user.username }
          ];

          if (comment.userId !== user.id) {
            addNotification({
              type: 'reaction',
              dealId,
              commentId,
              userId: comment.userId,
              creatorId: user.id,
              title: 'コメントにリアクションがありました',
              message: `${user.username}さんがあなたのコメントに「${
                type === 'like' ? '❤️' : type === 'laugh' ? '😂' : '✅'
              }」しました`
            });
          }
        }

        return {
          ...comment,
          reactions: updatedReactions
        };
      });
    });
  };

  return {
    comments,
    addComment,
    addReply,
    addReaction
  };
}