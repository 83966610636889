import type { Category } from '../types';

export const ALL_CATEGORIES: Category[] = [
  {
    id: 'baby-kids',
    name: 'ベビー・キッズ',
    description: 'ベビー用品、子供服、おもちゃなど',
    count: 0,
    score: 0
  },
  {
    id: 'games-toys',
    name: 'ゲーム・おもちゃ',
    description: 'ゲーム機、ボードゲーム、おもちゃなど',
    count: 0,
    score: 0
  },
  {
    id: 'hobby-instruments',
    name: 'ホビー・楽器',
    description: 'プラモデル、楽器、コレクションなど',
    count: 0,
    score: 0
  },
  {
    id: 'books-manga',
    name: '本・雑誌・漫画',
    description: '書籍、コミック、雑誌など',
    count: 0,
    score: 0
  },
  {
    id: 'pc-smartphone',
    name: 'スマホ・パソコン',
    description: 'スマートフォン、PC、タブレットなど',
    count: 0,
    score: 0
  },
  {
    id: 'appliances',
    name: '生活家電',
    description: '冷蔵庫、洗濯機、掃除機など',
    count: 0,
    score: 0
  },
  {
    id: 'sports',
    name: 'スポーツ',
    description: 'スポーツ用品、ウェア、シューズなど',
    count: 0,
    score: 0
  },
  {
    id: 'outdoor',
    name: 'アウトドア',
    description: 'キャンプ用品、釣り具、登山用品など',
    count: 0,
    score: 0
  },
  {
    id: 'beauty',
    name: 'コスメ・美容',
    description: '化粧品、スキンケア、ヘアケアなど',
    count: 0,
    score: 0
  },
  {
    id: 'health-diet',
    name: 'ダイエット・健康',
    description: 'サプリメント、トレーニング用品など',
    count: 0,
    score: 0
  },
  {
    id: 'food-drink',
    name: '食品・飲料',
    description: '食品、飲料、お菓子など',
    count: 0,
    score: 0
  },
  {
    id: 'kitchen-daily',
    name: 'キッチン・日用品',
    description: '調理器具、掃除用品、収納用品など',
    count: 0,
    score: 0
  },
  {
    id: 'furniture',
    name: '家具・インテリア',
    description: '家具、照明、カーテンなど',
    count: 0,
    score: 0
  },
  {
    id: 'pet',
    name: 'ペット用品',
    description: 'ペットフード、ペット用品など',
    count: 0,
    score: 0
  },
  {
    id: 'tools',
    name: '工具',
    description: 'DIY用品、電動工具、作業用品など',
    count: 0,
    score: 0
  },
  {
    id: 'garden',
    name: 'フラワー・ガーデニング',
    description: '花、植物、ガーデニング用品など',
    count: 0,
    score: 0
  },
  {
    id: 'handmade',
    name: 'ハンドメイド・手芸',
    description: '手芸用品、材料、道具など',
    count: 0,
    score: 0
  },
  {
    id: 'vehicles',
    name: '車・バイク・自転車',
    description: '自動車用品、バイク用品、自転車など',
    count: 0,
    score: 0
  },
  {
    id: 'others',
    name: 'その他',
    description: 'その他のカテゴリーに属さない商品',
    count: 0,
    score: 0
  }
];