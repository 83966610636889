import { useState, useMemo } from 'react';
import type { Deal } from '../types';

// 検索のウェイト付け
const WEIGHTS = {
  TITLE: 3,      // タイトルの一致は最も重要
  MERCHANT: 2,   // ショップ名の一致は次に重要
  DESCRIPTION: 1 // 説明文の一致は補助的
};

// 検索結果の最小スコア閾値
const MIN_SCORE = 0.3;

export function useSearch(deals: Deal[]) {
  const [searchQuery, setSearchQuery] = useState('');

  const searchResults = useMemo(() => {
    if (!searchQuery.trim()) {
      return deals;
    }

    const keywords = searchQuery.toLowerCase().trim().split(/\s+/);
    
    return deals
      .map(deal => {
        let score = 0;
        const matchedKeywords = new Set<string>();

        keywords.forEach(keyword => {
          // タイトルの検索
          if (deal.title.toLowerCase().includes(keyword)) {
            score += WEIGHTS.TITLE;
            matchedKeywords.add(keyword);
          }

          // ショップ名の検索
          if (deal.merchant.toLowerCase().includes(keyword)) {
            score += WEIGHTS.MERCHANT;
            matchedKeywords.add(keyword);
          }

          // 説明文の検索
          if (deal.description?.toLowerCase().includes(keyword)) {
            score += WEIGHTS.DESCRIPTION;
            matchedKeywords.add(keyword);
          }
        });

        // キーワードの一致率でスコアを正規化
        const normalizedScore = score / (keywords.length * (WEIGHTS.TITLE + WEIGHTS.MERCHANT + WEIGHTS.DESCRIPTION));

        return {
          deal,
          score: normalizedScore,
          matchedKeywords: Array.from(matchedKeywords)
        };
      })
      .filter(result => result.score >= MIN_SCORE)
      .sort((a, b) => b.score - a.score)
      .map(result => result.deal);
  }, [deals, searchQuery]);

  return {
    searchQuery,
    setSearchQuery,
    searchResults,
    isSearching: searchQuery.trim().length > 0
  };
}