import { useState, useEffect, useMemo } from 'react';
import { SAMPLE_DEALS } from '../data/sampleDeals';
import type { Deal } from '../types';

export type SortType = 'trend' | 'new';

export function useDeals() {
  const [deals, setDeals] = useState<Deal[]>(() => {
    const savedDeals = localStorage.getItem('deals');
    return savedDeals ? JSON.parse(savedDeals) : SAMPLE_DEALS;
  });

  const [sortType, setSortType] = useState<SortType>('trend');

  useEffect(() => {
    localStorage.setItem('deals', JSON.stringify(deals));
  }, [deals]);

  const sortedDeals = useMemo(() => {
    return [...deals].sort((a, b) => {
      if (sortType === 'trend') {
        return b.votes - a.votes;
      }
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
    });
  }, [deals, sortType]);

  const addDeal = (newDeal: Partial<Deal>) => {
    const deal: Deal = {
      id: Date.now().toString(),
      votes: 0,
      comments: 0,
      posted: '数分前',
      createdAt: new Date().toISOString(),
      ...newDeal
    } as Deal;

    setDeals(prevDeals => [deal, ...prevDeals]);
    return deal;
  };

  const getDealById = (id: string) => {
    return deals.find(deal => deal.id === id);
  };

  const getDealsByMerchant = (merchant: string) => {
    return deals.filter(deal => deal.merchant === merchant);
  };

  const getDealsByCategory = (category: string) => {
    return deals.filter(deal => deal.category === category);
  };

  const clearAllDeals = () => {
    setDeals(SAMPLE_DEALS);
    localStorage.setItem('deals', JSON.stringify(SAMPLE_DEALS));
  };

  return {
    deals: sortedDeals,
    rawDeals: deals,
    sortType,
    setSortType,
    addDeal,
    getDealById,
    getDealsByMerchant,
    getDealsByCategory,
    clearAllDeals
  };
}