import React, { useState, useRef, useEffect } from 'react';
import { MessageSquare, Heart } from 'lucide-react';
import type { ReactionType, Comment } from '../types';
import { useAuth } from '../hooks/useAuth';

interface CommentReactionsProps {
  comment: Comment;
  onReact: (type: ReactionType) => void;
  onReply: () => void;
}

export default function CommentReactions({ comment, onReact, onReply }: CommentReactionsProps) {
  const [showReactions, setShowReactions] = useState(false);
  const { user } = useAuth();
  const reactionMenuRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<NodeJS.Timeout>();

  const reactionCounts = {
    like: comment.reactions.like.length,
    laugh: comment.reactions.laugh.length,
    helpful: comment.reactions.helpful.length
  };

  const totalReactions = reactionCounts.like + reactionCounts.laugh + reactionCounts.helpful;

  const userReactions = user ? {
    like: comment.reactions.like.some(r => r.userId === user.id),
    laugh: comment.reactions.laugh.some(r => r.userId === user.id),
    helpful: comment.reactions.helpful.some(r => r.userId === user.id)
  } : null;

  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const handleMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setShowReactions(true);
  };

  const handleMouseLeave = () => {
    timeoutRef.current = setTimeout(() => {
      setShowReactions(false);
    }, 300);
  };

  const handleReact = (type: ReactionType) => {
    if (!user) return;
    onReact(type);
  };

  const getReactionDisplay = () => {
    const reactions = [];
    if (reactionCounts.like > 0) {
      reactions.push(
        <span key="like" className="text-[#ff4b6e]">
          ❤️ {reactionCounts.like}
        </span>
      );
    }
    if (reactionCounts.laugh > 0) {
      reactions.push(
        <span key="laugh" className="text-[#ffb626]">
          😂 {reactionCounts.laugh}
        </span>
      );
    }
    if (reactionCounts.helpful > 0) {
      reactions.push(
        <span key="helpful" className="text-[#2ea043]">
          ✅ {reactionCounts.helpful}
        </span>
      );
    }
    return (
      <div className="flex items-center space-x-2">
        {reactions.map((reaction, index) => (
          <React.Fragment key={index}>
            {index > 0 && <span className="text-gray-300">•</span>}
            {reaction}
          </React.Fragment>
        ))}
      </div>
    );
  };

  return (
    <div className="flex items-center space-x-4">
      <div className="relative">
        <button
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="text-gray-500 hover:text-red-600 flex items-center space-x-1 p-1 rounded-md transition-colors"
        >
          {totalReactions > 0 ? (
            getReactionDisplay()
          ) : (
            <span className="text-sm text-gray-500 flex items-center">
              <Heart className="h-4 w-4 mr-1" />
              いいね
            </span>
          )}
        </button>

        {showReactions && (
          <div
            ref={reactionMenuRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="absolute bottom-full left-0 mb-2 bg-white rounded-lg shadow-lg border border-gray-100 p-2 flex space-x-2 z-10"
          >
            <button
              onClick={() => handleReact('like')}
              className={`p-2 rounded-full hover:bg-gray-100 transition-colors ${
                userReactions?.like ? 'bg-red-50' : ''
              }`}
              title="いいね"
            >
              <span className="text-lg select-none">❤️</span>
            </button>
            <button
              onClick={() => handleReact('laugh')}
              className={`p-2 rounded-full hover:bg-gray-100 transition-colors ${
                userReactions?.laugh ? 'bg-yellow-50' : ''
              }`}
              title="(笑)"
            >
              <span className="text-lg select-none">😂</span>
            </button>
            <button
              onClick={() => handleReact('helpful')}
              className={`p-2 rounded-full hover:bg-gray-100 transition-colors ${
                userReactions?.helpful ? 'bg-green-50' : ''
              }`}
              title="助かりました"
            >
              <span className="text-lg select-none">✅</span>
            </button>
          </div>
        )}
      </div>

      <button
        onClick={onReply}
        className="text-gray-500 hover:text-red-600 flex items-center space-x-1 p-1 rounded-md transition-colors"
      >
        <MessageSquare className="h-4 w-4" />
        <span>返信</span>
      </button>
    </div>
  );
}