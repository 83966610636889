import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { MessageCircle, ExternalLink, Heart } from 'lucide-react';
import VoteButtons from './VoteButtons';
import { useFavorites } from '../hooks/useFavorites';
import { useAuth } from '../hooks/useAuth';
import AuthModal from './AuthModal';
import type { Deal } from '../types';

interface DealCardProps extends Deal {}

export default function DealCard({
  id,
  title,
  price,
  originalPrice,
  discount,
  image,
  merchant,
  votes,
  comments,
  posted,
  url,
  username = 'ユーザー',
}: DealCardProps) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { isFavorite, toggleFavorite } = useFavorites();
  const [showAuthModal, setShowAuthModal] = React.useState(false);

  const formatPrice = (value: number | null | undefined) => {
    if (typeof value !== 'number') return '0';
    return value.toLocaleString();
  };

  const handleCardClick = () => {
    navigate(`/deal/${id}`);
  };

  const handleStopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleFavoriteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!user) {
      setShowAuthModal(true);
      return;
    }
    toggleFavorite(id);
  };

  if (!id || !title) return null;

  return (
    <>
      <div 
        id={`deal-${id}`}
        className="bg-white rounded-lg shadow-sm hover:shadow-md transition-all duration-200 overflow-hidden border border-gray-100 cursor-pointer relative highlight-new-deal"
        onClick={handleCardClick}
      >
        {/* Favorite Button */}
        <button
          onClick={handleFavoriteClick}
          className={`absolute top-2 right-2 p-2 rounded-full bg-white shadow-md z-10 transition-colors ${
            isFavorite(id)
              ? 'text-red-600 hover:text-red-700'
              : 'text-gray-400 hover:text-red-600'
          }`}
        >
          <Heart
            className={`h-5 w-5 ${isFavorite(id) ? 'fill-current' : ''}`}
          />
        </button>

        <div className="flex flex-col md:flex-row">
          <div className="w-full md:w-48 h-48 flex-shrink-0">
            <img 
              src={image || 'https://via.placeholder.com/400x400?text=No+Image'} 
              alt={title} 
              className="w-full h-full object-cover"
            />
          </div>
          
          <div className="flex-1 p-4">
            <div className="flex items-start justify-between">
              <div className="flex-1">
                <div className="flex items-center space-x-2 mb-2">
                  <Link 
                    to={`/merchant/${encodeURIComponent(merchant)}`}
                    className="text-sm text-gray-500 hover:text-red-600"
                    onClick={handleStopPropagation}
                  >
                    {merchant}
                  </Link>
                  <span className="text-sm text-gray-400">・</span>
                  <span className="text-sm text-gray-400">{posted}</span>
                  <span className="text-sm text-gray-400">・</span>
                  <span className="text-sm text-gray-400">{username}</span>
                </div>
                
                <h3 className="text-lg font-semibold mb-2 line-clamp-2 hover:text-red-600">
                  {title}
                </h3>

                <div className="flex items-baseline mb-4">
                  <span className="text-2xl font-bold text-red-600">
                    ¥{formatPrice(price)}
                  </span>
                  {originalPrice && (
                    <>
                      <span className="ml-2 text-sm text-gray-400 line-through">
                        ¥{formatPrice(originalPrice)}
                      </span>
                      <span className="ml-2 bg-red-600 text-white px-2 py-1 rounded text-sm font-semibold">
                        {discount}% OFF
                      </span>
                    </>
                  )}
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-4" onClick={handleStopPropagation}>
                    <VoteButtons dealId={id} initialScore={votes || 0} size="sm" />
                    <button className="flex items-center text-gray-500 hover:text-red-600">
                      <MessageCircle className="h-4 w-4 mr-1" />
                      <span>{comments || 0}</span>
                    </button>
                  </div>
                  
                  {url && (
                    <a
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
                      onClick={handleStopPropagation}
                    >
                      <span>商品サイトへ</span>
                      <ExternalLink className="h-4 w-4 ml-1" />
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AuthModal 
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        message="お気に入りに追加するにはログインが必要です。会員登録（無料）もしくはログインして、お気に入り機能をご利用ください。"
      />
    </>
  );
}