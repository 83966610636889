import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Home, Heart } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { useCategories } from '../hooks/useCategories';
import AuthModal from './AuthModal';

export default function Sidebar() {
  const { user } = useAuth();
  const { popularCategories } = useCategories();
  const [showAuthModal, setShowAuthModal] = useState(false);

  const handleFavoritesClick = (e: React.MouseEvent) => {
    if (!user) {
      e.preventDefault();
      setShowAuthModal(true);
    }
  };

  return (
    <div className="hidden md:block w-64 bg-white border-r border-gray-100 p-4">
      <nav className="space-y-1">
        <Link to="/" className="flex items-center px-4 py-2 text-red-600 bg-red-50 rounded-lg">
          <Home className="h-5 w-5 mr-3" />
          ホーム
        </Link>
        <Link
          to={user ? "/favorites" : "#"}
          className="flex items-center px-4 py-2 text-gray-700 hover:bg-gray-50 rounded-lg w-full"
          onClick={handleFavoritesClick}
        >
          <Heart className="h-5 w-5 mr-3" />
          お気に入り
        </Link>
      </nav>

      <div className="mt-8">
        <h3 className="px-4 text-sm font-semibold text-gray-500 uppercase tracking-wider">
          人気カテゴリー
        </h3>
        <div className="mt-4 space-y-1">
          {popularCategories.map((category) => (
            <Link
              key={category.id}
              to={`/category/${encodeURIComponent(category.name)}`}
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-lg"
            >
              <div className="flex items-center justify-between">
                <span>{category.name}</span>
                {category.count > 0 && (
                  <span className="text-xs text-gray-500">
                    ({category.count})
                  </span>
                )}
              </div>
            </Link>
          ))}
          <Link
            to="/categories"
            className="block px-4 py-2 text-sm text-red-600 hover:bg-gray-50 rounded-lg"
          >
            すべてのカテゴリーを見る
          </Link>
        </div>
      </div>

      <AuthModal 
        isOpen={showAuthModal} 
        onClose={() => setShowAuthModal(false)} 
      />
    </div>
  );
}