import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import DealCard from './components/DealCard';
import DealPage from './components/DealPage';
import SupplierDeals from './components/SupplierDeals';
import CategoryPage from './components/CategoryPage';
import CategoriesPage from './components/CategoriesPage';
import FavoritesPage from './components/FavoritesPage';
import ProfilePage from './components/ProfilePage';
import SettingsPage from './components/SettingsPage';
import GuidelinesPage from './components/GuidelinesPage';
import FaqPage from './components/FaqPage';
import ContactPage from './components/ContactPage';
import PrivacyPolicyPage from './components/PrivacyPolicyPage';
import LegalPage from './components/LegalPage';
import TermsPage from './components/TermsPage';
import AboutPage from './components/AboutPage';
import Footer from './components/Footer';
import { useDeals } from './hooks/useDeals';
import { useSearch } from './hooks/useSearch';
import { VoteProvider } from './hooks/useVoteContext';

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const { deals, sortType, setSortType } = useDeals();
  const { searchQuery, setSearchQuery, searchResults, isSearching } = useSearch(deals);

  const displayedDeals = isSearching ? searchResults : deals;

  return (
    <Router>
      <VoteProvider>
        <ScrollToTop />
        <div className="min-h-screen bg-gray-50">
          <Header searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
          
          <div className="flex justify-center">
            <div className="w-full max-w-7xl flex">
              <Sidebar />
              
              <main className="flex-1 min-w-0">
                <Routes>
                  <Route 
                    path="/" 
                    element={
                      <div className="px-4 sm:px-6 py-8 max-w-4xl mx-auto">
                        <div className="mb-6">
                          <div className="flex space-x-4 border-b border-gray-200">
                            <button
                              className={`px-4 py-2 font-medium text-sm ${
                                sortType === 'trend'
                                  ? 'text-red-600 border-b-2 border-red-600'
                                  : 'text-gray-500 hover:text-gray-700'
                              }`}
                              onClick={() => setSortType('trend')}
                            >
                              トレンド入り
                            </button>
                            <button
                              className={`px-4 py-2 font-medium text-sm ${
                                sortType === 'new'
                                  ? 'text-red-600 border-b-2 border-red-600'
                                  : 'text-gray-500 hover:text-gray-700'
                              }`}
                              onClick={() => setSortType('new')}
                            >
                              新着順
                            </button>
                          </div>
                        </div>

                        {isSearching && searchResults.length === 0 ? (
                          <div className="text-center py-12">
                            <p className="text-gray-500 mb-2">
                              「{searchQuery}」に一致する商品が見つかりませんでした
                            </p>
                            <p className="text-sm text-gray-400">
                              別のキーワードで試してみてください
                            </p>
                          </div>
                        ) : (
                          <div className="grid grid-cols-1 gap-6">
                            {displayedDeals.map((deal) => (
                              <DealCard key={deal.id} {...deal} />
                            ))}
                          </div>
                        )}
                      </div>
                    } 
                  />
                  <Route path="/deal/:id" element={<DealPage />} />
                  <Route path="/merchant/:supplier" element={<SupplierDeals />} />
                  <Route path="/category/:category" element={<CategoryPage />} />
                  <Route path="/categories" element={<CategoriesPage />} />
                  <Route path="/favorites" element={<FavoritesPage />} />
                  <Route path="/profile" element={<ProfilePage />} />
                  <Route path="/settings" element={<SettingsPage />} />
                  <Route path="/guidelines" element={<GuidelinesPage />} />
                  <Route path="/faq" element={<FaqPage />} />
                  <Route path="/contact" element={<ContactPage />} />
                  <Route path="/privacy" element={<PrivacyPolicyPage />} />
                  <Route path="/legal" element={<LegalPage />} />
                  <Route path="/terms" element={<TermsPage />} />
                  <Route path="/about" element={<AboutPage />} />
                </Routes>
              </main>
            </div>
          </div>
          <Footer />
        </div>
      </VoteProvider>
    </Router>
  );
}

export default App;