import React from 'react';
import { Heart } from 'lucide-react';
import DealCard from './DealCard';
import { useFavorites } from '../hooks/useFavorites';
import { useDeals } from '../hooks/useDeals';

export default function FavoritesPage() {
  const { favorites } = useFavorites();
  const { deals } = useDeals();
  
  const favoriteDeals = deals.filter(deal => favorites.includes(deal.id));

  if (favoriteDeals.length === 0) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 py-8">
        <div className="flex items-center space-x-2 mb-6">
          <Heart className="h-6 w-6 text-gray-600" />
          <h1 className="text-2xl font-bold">お気に入り</h1>
        </div>
        
        <div className="text-center py-12 bg-white rounded-lg shadow-sm">
          <Heart className="h-12 w-12 text-gray-400 mx-auto mb-4" />
          <h2 className="text-xl font-semibold text-gray-900 mb-2">
            お気に入りはまだありません
          </h2>
          <p className="text-gray-600">
            気になる商品をお気に入りに追加して、後で確認することができます。
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 py-8">
      <div className="flex items-center space-x-2 mb-6">
        <Heart className="h-6 w-6 text-gray-600" />
        <h1 className="text-2xl font-bold">お気に入り</h1>
        <span className="text-gray-500">({favoriteDeals.length})</span>
      </div>

      <div className="grid grid-cols-1 gap-6">
        {favoriteDeals.map(deal => (
          <DealCard key={deal.id} {...deal} />
        ))}
      </div>
    </div>
  );
}