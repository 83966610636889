import React, { useState } from 'react';
import { HelpCircle, ChevronDown, ChevronUp } from 'lucide-react';

interface FaqItem {
  question: string;
  answer: string;
  category: string;
}

const faqs: FaqItem[] = [
  {
    category: "サービスについて",
    question: "お得ズとはどんなサービスですか？",
    answer: "お得ズは、ユーザーの皆様が見つけたお得な商品情報を共有するコミュニティサイトです。セール情報や割引クーポン、タイムセールなど、様々なお得情報をユーザー同士で共有することができます。"
  },
  {
    category: "サービスについて",
    question: "利用料金はかかりますか？",
    answer: "お得ズの基本的な機能はすべて無料でご利用いただけます。会員登録も無料です。"
  },
  {
    category: "投稿について",
    question: "お得な情報を投稿するにはどうすればいいですか？",
    answer: "会員登録後、ヘッダーの「投稿する」ボタンから情報を投稿できます。商品名、価格、セール情報の詳細など、必要な情報を入力してください。"
  },
  {
    category: "投稿について",
    question: "投稿の編集や削除はできますか？",
    answer: "はい、ご自身が投稿した情報は後から編集・削除が可能です。投稿の詳細ページから該当の操作を行ってください。"
  },
  {
    category: "アカウントについて",
    question: "パスワードを忘れてしまいました",
    answer: "ログインページの「パスワードをお忘れの方」から、登録されているメールアドレスにパスワードリセットのリンクを送信できます。"
  },
  {
    category: "アカウントについて",
    question: "アカウントを削除したいです",
    answer: "設定ページからアカウントの削除が可能です。なお、投稿した情報は匿名化した上で残りますのでご了承ください。"
  },
  {
    category: "お気に入り機能",
    question: "お気に入りの使い方を教えてください",
    answer: "気になる商品の横にあるハートマークをクリックすることで、お気に入りに登録できます。登録した商品は「お気に入り」ページからいつでも確認できます。"
  },
  {
    category: "通知設定",
    question: "メール通知を停止したいです",
    answer: "設定ページの「通知設定」から、各種通知のオン/オフを切り替えることができます。"
  },
  {
    category: "その他",
    question: "不適切な投稿を見つけました",
    answer: "各投稿の「報告する」ボタンから、不適切な内容を報告することができます。運営チームが確認の上、適切な対応を取らせていただきます。"
  },
  {
    category: "その他",
    question: "広告掲載について問い合わせたいです",
    answer: "法人様からの広告掲載のお問い合わせは、お問い合わせフォームより「広告掲載について」を選択の上、ご連絡ください。"
  }
];

export default function FaqPage() {
  const [openItems, setOpenItems] = useState<number[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

  const categories = Array.from(new Set(faqs.map(faq => faq.category)));

  const toggleItem = (index: number) => {
    setOpenItems(prev =>
      prev.includes(index)
        ? prev.filter(i => i !== index)
        : [...prev, index]
    );
  };

  const filteredFaqs = selectedCategory
    ? faqs.filter(faq => faq.category === selectedCategory)
    : faqs;

  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center mb-6">
          <HelpCircle className="h-6 w-6 text-red-600 mr-2" />
          <h1 className="text-2xl font-bold">よくある質問</h1>
        </div>

        {/* カテゴリーフィルター */}
        <div className="mb-8">
          <div className="flex flex-wrap gap-2">
            <button
              onClick={() => setSelectedCategory(null)}
              className={`px-4 py-2 rounded-full text-sm ${
                selectedCategory === null
                  ? 'bg-red-600 text-white'
                  : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
              }`}
            >
              すべて
            </button>
            {categories.map(category => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-4 py-2 rounded-full text-sm ${
                  selectedCategory === category
                    ? 'bg-red-600 text-white'
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                }`}
              >
                {category}
              </button>
            ))}
          </div>
        </div>

        {/* FAQ リスト */}
        <div className="space-y-4">
          {filteredFaqs.map((faq, index) => (
            <div
              key={index}
              className="border border-gray-200 rounded-lg overflow-hidden"
            >
              <button
                onClick={() => toggleItem(index)}
                className="w-full px-6 py-4 text-left flex justify-between items-center hover:bg-gray-50"
              >
                <span className="font-medium">{faq.question}</span>
                {openItems.includes(index) ? (
                  <ChevronUp className="h-5 w-5 text-gray-400" />
                ) : (
                  <ChevronDown className="h-5 w-5 text-gray-400" />
                )}
              </button>
              {openItems.includes(index) && (
                <div className="px-6 py-4 bg-gray-50 border-t border-gray-200">
                  <p className="text-gray-600">{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>

        {/* 問い合わせ誘導 */}
        <div className="mt-8 p-6 bg-gray-50 rounded-lg">
          <p className="text-center text-gray-600">
            お探しの質問が見つからない場合は、
            <a href="/contact" className="text-red-600 hover:text-red-700">
              お問い合わせフォーム
            </a>
            からご連絡ください。
          </p>
        </div>
      </div>
    </div>
  );
}