import { useState, useEffect, useMemo } from 'react';
import { ALL_CATEGORIES } from '../data/categories';
import { useDeals } from './useDeals';
import type { Category } from '../types';

const POPULAR_CATEGORIES_COUNT = 8;
const VOTE_WEIGHT = 0.3;
const COMMENT_WEIGHT = 0.2;
const DEAL_COUNT_WEIGHT = 0.5;

// 固定の人気カテゴリー順序
const FIXED_POPULAR_ORDER = [
  'pc-smartphone',    // スマホ・パソコン
  'appliances',       // 生活家電
  'food-drink',       // 食品・飲料
  'sports',          // スポーツ
  'beauty',          // コスメ・美容
  'furniture',       // 家具・インテリア
  'kitchen-daily',   // キッチン・日用品
  'baby-kids'        // ベビー・キッズ
];

export function useCategories() {
  const { deals } = useDeals();
  const [categories, setCategories] = useState<Category[]>(ALL_CATEGORIES);

  // カテゴリーのスコアと商品数を計算
  useEffect(() => {
    const categoryStats = ALL_CATEGORIES.map(category => {
      const categoryDeals = deals.filter(deal => deal.category === category.name);
      const dealCount = categoryDeals.length;
      
      // 投票数、コメント数の合計を計算
      const totalVotes = categoryDeals.reduce((sum, deal) => sum + deal.votes, 0);
      const totalComments = categoryDeals.reduce((sum, deal) => sum + deal.comments, 0);
      
      // スコアの計算
      const score = 
        (dealCount * DEAL_COUNT_WEIGHT) + 
        (totalVotes * VOTE_WEIGHT) + 
        (totalComments * COMMENT_WEIGHT);

      return {
        ...category,
        count: dealCount,
        score
      };
    });

    setCategories(categoryStats);
  }, [deals]);

  // 固定順序で人気カテゴリーを取得
  const popularCategories = useMemo(() => {
    return FIXED_POPULAR_ORDER
      .map(id => categories.find(cat => cat.id === id))
      .filter((cat): cat is Category => cat !== undefined);
  }, [categories]);

  return {
    categories,
    popularCategories,
    allCategories: ALL_CATEGORIES
  };
}