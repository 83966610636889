import React, { createContext, useContext, useState } from 'react';

interface VoteState {
  [dealId: string]: {
    score: number;
    userVote: 'up' | 'down' | null;
  };
}

interface VoteContextType {
  votes: VoteState;
  handleVote: (dealId: string, voteType: 'up' | 'down' | null) => void;
  getVoteState: (dealId: string) => {
    score: number;
    userVote: 'up' | 'down' | null;
  };
}

const VoteContext = createContext<VoteContextType | null>(null);

export function VoteProvider({ children }: { children: React.ReactNode }) {
  const [votes, setVotes] = useState<VoteState>(() => {
    const saved = localStorage.getItem('votes');
    return saved ? JSON.parse(saved) : {};
  });

  const handleVote = (dealId: string, voteType: 'up' | 'down' | null) => {
    setVotes(prev => {
      const currentState = prev[dealId] || { score: 0, userVote: null };
      let newScore = currentState.score;

      // Remove previous vote if exists
      if (currentState.userVote === 'up') newScore--;
      if (currentState.userVote === 'down') newScore++;

      // Add new vote
      if (voteType === 'up') newScore++;
      if (voteType === 'down') newScore--;

      const newState = {
        ...prev,
        [dealId]: {
          score: newScore,
          userVote: voteType
        }
      };

      // Persist to localStorage
      localStorage.setItem('votes', JSON.stringify(newState));
      return newState;
    });
  };

  const getVoteState = (dealId: string) => {
    return votes[dealId] || { score: 0, userVote: null };
  };

  return (
    <VoteContext.Provider value={{ votes, handleVote, getVoteState }}>
      {children}
    </VoteContext.Provider>
  );
}

export function useVote(dealId: string) {
  const context = useContext(VoteContext);
  if (!context) {
    throw new Error('useVote must be used within a VoteProvider');
  }

  const { getVoteState, handleVote } = context;
  const { score, userVote } = getVoteState(dealId);

  const vote = (voteType: 'up' | 'down' | null) => {
    handleVote(dealId, voteType);
  };

  return {
    score,
    userVote,
    handleVote: vote
  };
}