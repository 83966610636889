import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="bg-gray-50 border-t border-gray-200 mt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 py-12">
        {/* SEO Text */}
        <div className="max-w-3xl mb-12">
          <p className="text-sm text-gray-600 leading-relaxed">
            【専門家が厳選】話題の商品がお得に買える！セール情報・割引クーポン・タイムセールなど、リアルタイムで更新中。自動収集ではない、プロの目利きによって厳選されたお得情報を毎日お届け。
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Service Info */}
          <div className="col-span-1">
            <h3 className="text-lg font-bold mb-4">サービスについて</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <Link to="/about" className="text-gray-600 hover:text-red-600">
                  サービス概要
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-gray-600 hover:text-red-600">
                  利用規約
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="text-gray-600 hover:text-red-600">
                  プライバシーポリシー
                </Link>
              </li>
              <li>
                <Link to="/legal" className="text-gray-600 hover:text-red-600">
                  特定商取引法に基づく表記
                </Link>
              </li>
            </ul>
          </div>

          {/* Help & Support */}
          <div className="col-span-1">
            <h3 className="text-lg font-bold mb-4">ヘルプ＆サポート</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <Link to="/faq" className="text-gray-600 hover:text-red-600">
                  よくある質問
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-gray-600 hover:text-red-600">
                  お問い合わせ
                </Link>
              </li>
              <li>
                <Link to="/guidelines" className="text-gray-600 hover:text-red-600">
                  ガイドライン
                </Link>
              </li>
            </ul>
          </div>

          {/* Social Links */}
          <div className="col-span-1">
            <h3 className="text-lg font-bold mb-4">ソーシャルメディア</h3>
            <ul className="space-y-2 text-sm">
              <li>
                <a href="https://twitter.com/tokulockjp" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-red-600">
                  Twitter
                </a>
              </li>
              <li>
                <a href="https://instagram.com/tokulockjp" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-red-600">
                  Instagram
                </a>
              </li>
              <li>
                <a href="https://line.me/ti/p/@tokulockjp" target="_blank" rel="noopener noreferrer" className="text-gray-600 hover:text-red-600">
                  LINE
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-12 pt-8 border-t border-gray-200">
          <p className="text-center text-sm text-gray-500">
            © {new Date().getFullYear()} とくロック All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}