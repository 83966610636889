import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';
import type { Deal } from '../types';

export function useFavorites() {
  const { user } = useAuth();
  const [favorites, setFavorites] = useState<string[]>(() => {
    if (!user) return [];
    const saved = localStorage.getItem(`favorites_${user.id}`);
    return saved ? JSON.parse(saved) : [];
  });

  useEffect(() => {
    if (user) {
      localStorage.setItem(`favorites_${user.id}`, JSON.stringify(favorites));
    }
  }, [favorites, user?.id]);

  const addFavorite = (dealId: string) => {
    if (!user) return false;
    setFavorites(prev => [...new Set([...prev, dealId])]);
    return true;
  };

  const removeFavorite = (dealId: string) => {
    if (!user) return false;
    setFavorites(prev => prev.filter(id => id !== dealId));
    return true;
  };

  const isFavorite = (dealId: string) => {
    return favorites.includes(dealId);
  };

  const toggleFavorite = (dealId: string) => {
    if (isFavorite(dealId)) {
      return removeFavorite(dealId);
    }
    return addFavorite(dealId);
  };

  return {
    favorites,
    addFavorite,
    removeFavorite,
    isFavorite,
    toggleFavorite
  };
}