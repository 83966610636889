import type { Deal } from '../types';

export const SAMPLE_DEALS: Deal[] = [
  {
    id: '1',
    title: "【本日限定】Nintendo Switch 有機ELモデル＋ゼルダの伝説 ティアーズ オブ ザ キングダム セット",
    price: 42800,
    originalPrice: 54800,
    discount: 22,
    image: "https://images.unsplash.com/photo-1578303512597-81e6cc155b3e?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80",
    merchant: "ヨドバシカメラ",
    votes: 523,
    comments: 48,
    posted: "1時間前",
    url: "#",
    username: "GameHunter",
    category: "ゲーム・おもちゃ",
    description: `🎮 期間限定セール実施中！

【セット内容】
• Nintendo Switch 有機ELモデル 本体
• ゼルダの伝説 ティアーズ オブ ザ キングダム ソフト
• オリジナルケース

【特典】
• ヨドバシポイント10%還元
• 送料無料
• 5年保証付き

【注意事項】
• お一人様1台限り
• 店頭受け取り可能
• 数量限定につき無くなり次第終了`
  },
  {
    id: '2',
    title: "【タイムセール】Apple MacBook Air M2 16GB/512GB",
    price: 158800,
    originalPrice: 198800,
    discount: 20,
    image: "https://images.unsplash.com/photo-1517336714731-489689fd1ca8?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80",
    merchant: "Amazon.co.jp",
    votes: 412,
    comments: 35,
    posted: "3時間前",
    url: "#",
    username: "TechDeals",
    category: "スマホ・パソコン",
    description: `【セール詳細】
• 40,000円OFF
• Amazonポイント5%還元
• Prime会員限定価格

【スペック】
• Apple M2チップ
• 16GBユニファイドメモリ
• 512GB SSD
• 13.6インチLiquid Retinaディスプレイ

【配送】
• Prime会員は翌日配送無料
• 設置サポート付き`
  },
  {
    id: '3',
    title: "【期間限定】ダイソン V15 Detect Complete + 純正スタンド セット",
    price: 89800,
    originalPrice: 128000,
    discount: 30,
    image: "https://images.unsplash.com/photo-1558317374-067fb5f30001?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80",
    merchant: "楽天市場",
    votes: 345,
    comments: 42,
    posted: "4時間前",
    url: "#",
    username: "CleaningPro",
    category: "生活家電",
    description: `🌟楽天スーパーSALE目玉商品🌟

【セット内容】
• V15 Detect Complete 本体
• 純正充電スタンド
• 各種アタッチメント一式

【ポイント】
• 楽天ポイント20倍
• 5年保証付き
• 送料無料

【クーポンコード】
DYSON30OFF`
  },
  {
    id: '4',
    title: "【今だけ】無印良品 収納・家具まとめ買いセール",
    price: 19800,
    originalPrice: 32800,
    discount: 40,
    image: "https://images.unsplash.com/photo-1524758631624-e2822e304c36?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80",
    merchant: "無印良品",
    votes: 289,
    comments: 31,
    posted: "6時間前",
    url: "#",
    username: "HomeStyle",
    category: "家具・インテリア",
    description: `【セット内容】
• スタッキングシェルフ3段
• 収納ケース6点
• 衣類収納ボックス4点

【特典】
• 組み立てサービス無料
• 送料無料（通常2,000円）
• MUJIカード会員は10%OFF

【注意事項】
• オンライン限定
• 数量限定`
  },
  {
    id: '5',
    title: "【数量限定】象印 炎舞炊き 5.5合炊き 圧力IH炊飯器",
    price: 32800,
    originalPrice: 49800,
    discount: 34,
    image: "https://images.unsplash.com/photo-1621274147744-cfb5694bb233?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80",
    merchant: "ビックカメラ",
    votes: 234,
    comments: 28,
    posted: "8時間前",
    url: "#",
    username: "KitchenLover",
    category: "生活家電",
    description: `【セール詳細】
• 17,000円OFF
• ビックポイント10%還元
• 長期保証半額

【商品特徴】
• 極上火加熱
• 7層圧釜
• うまみ循環加熱
• 白米12通りの炊き分け

【購入特典】
• 専用しゃもじ
• レシピブック付き
• 3年保証付き`
  }
];