import React from 'react';
import { Shield } from 'lucide-react';

export default function GuidelinesPage() {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center mb-6">
          <Shield className="h-6 w-6 text-red-600 mr-2" />
          <h1 className="text-2xl font-bold">ガイドライン</h1>
        </div>

        <div className="prose max-w-none">
          <p className="text-gray-600 mb-6">
            無料会員登録にあたり、以下の利用規約に同意いただきますようお願いいたします。
            弊サイトは、ユーザー皆様にお買い得情報をお届けすることを目的としております。円滑な運営のためには、ご利用者様の相互理解と協力が不可欠です。
          </p>

          <h2 className="text-xl font-semibold mt-8 mb-4">禁止事項</h2>
          <p className="text-gray-600 mb-4">以下の行為は固く禁じさせていただきます：</p>
          
          <ul className="list-disc list-inside space-y-2 text-gray-600 mb-6">
            <li>違法行為や人権侵害にあたる投稿</li>
            <li>違法ソフトウェアや著作権侵害に関する情報の提供</li>
            <li>誹謗中傷や煽動的な書き込み</li>
            <li>複数アカウントの利用</li>
            <li>アフィリエイトリンクの掲載、自社製品・サービスの宣伝、フリマサイトからの転売品の出品</li>
            <li>電子タバコ、たばこ製品、催涙スプレーなどの危険物の販売</li>
            <li>ポルノグラフィックコンテンツ</li>
            <li>政治的・宗教的主張</li>
            <li>スパム行為全般</li>
            <li>署名の使用</li>
          </ul>

          <h2 className="text-xl font-semibold mt-8 mb-4">違反への対応</h2>
          <p className="text-gray-600 mb-6">
            こうした利用規約に反する行為が確認された場合、弊社の判断により投稿の編集・削除、アカウントの停止等の措置を講じさせていただきます。
            重大な違反があった場合は、事前警告なしでアカウントを停止する場合もございます。
          </p>

          <h2 className="text-xl font-semibold mt-8 mb-4">個人情報の取り扱い</h2>
          <p className="text-gray-600 mb-6">
            ご登録情報は第三者への提供を一切いたしません。スパム行為は厳しく取り締まってまいります。
          </p>

          <h2 className="text-xl font-semibold mt-8 mb-4">アカウントの削除</h2>
          <p className="text-gray-600">
            ご希望により、いつでもアカウントの削除を承っております。ただし投稿内容につきましては、他の投稿との関連性を損なわないよう、
            匿名化の上で保管させていただきます。
          </p>
        </div>
      </div>
    </div>
  );
}