import { useState, useEffect } from 'react';
import { useAuth } from './useAuth';
import type { Notification, NotificationType } from '../types';

interface AddNotificationParams {
  type: NotificationType;
  dealId: string;
  commentId?: string;
  userId: string;
  creatorId: string;
  title: string;
  message: string;
}

export function useNotifications() {
  const { user } = useAuth();
  const [notifications, setNotifications] = useState<Notification[]>(() => {
    if (!user) return [];
    const saved = localStorage.getItem(`notifications_${user.id}`);
    return saved ? JSON.parse(saved) : [];
  });

  useEffect(() => {
    if (user) {
      localStorage.setItem(`notifications_${user.id}`, JSON.stringify(notifications));
    }
  }, [notifications, user]);

  const addNotification = ({
    type,
    dealId,
    commentId,
    userId,
    creatorId,
    title,
    message
  }: AddNotificationParams) => {
    if (userId === creatorId) return; // Don't notify users about their own actions

    const newNotification: Notification = {
      id: Date.now().toString(),
      type,
      dealId,
      commentId,
      userId,
      creatorId,
      title,
      message,
      read: false,
      createdAt: new Date().toISOString()
    };

    setNotifications(prev => [newNotification, ...prev]);
  };

  const markAsRead = (notificationId: string) => {
    setNotifications(prev =>
      prev.map(n =>
        n.id === notificationId ? { ...n, read: true } : n
      )
    );
  };

  const markAllAsRead = () => {
    setNotifications(prev =>
      prev.map(n => ({ ...n, read: true }))
    );
  };

  const getUnreadCount = () => {
    return notifications.filter(n => !n.read).length;
  };

  return {
    notifications: notifications.filter(n => n.userId === user?.id),
    unreadCount: getUnreadCount(),
    addNotification,
    markAsRead,
    markAllAsRead
  };
}