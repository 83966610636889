import React from 'react';
import DealCard from './DealCard';
import { getRecommendedDeals } from '../utils/recommendationLogic';
import type { Deal } from '../types';

interface RelatedDealsProps {
  currentDeal: Deal;
  allDeals: Deal[];
}

export default function RelatedDeals({ currentDeal, allDeals }: RelatedDealsProps) {
  if (!currentDeal || !Array.isArray(allDeals) || allDeals.length === 0) {
    return null;
  }

  const recommendedDeals = getRecommendedDeals(currentDeal, allDeals);

  // Don't show the section at all if there are no recommendations
  if (recommendedDeals.length === 0) {
    return null;
  }

  return (
    <div className="mt-12">
      <h2 className="text-xl font-bold mb-6">関連商品</h2>
      <div className="grid grid-cols-1 gap-6">
        {recommendedDeals.map(deal => (
          <DealCard key={deal.id} {...deal} />
        ))}
      </div>
    </div>
  );
}