import React, { useState } from 'react';
import { formatDate } from '../utils/dateUtils';
import CommentReactions from './CommentReactions';
import type { Comment, ReactionType } from '../types';
import { useAuth } from '../hooks/useAuth';

interface CommentItemProps {
  comment: Comment;
  onReact: (commentId: string, type: ReactionType) => void;
  onReply: (parentId: string) => void;
  level?: number;
}

export default function CommentItem({ 
  comment, 
  onReact, 
  onReply,
  level = 0 
}: CommentItemProps) {
  const [isReplying, setIsReplying] = useState(false);
  const [replyContent, setReplyContent] = useState('');
  const { user } = useAuth();

  const handleReact = (type: ReactionType) => {
    onReact(comment.id, type);
  };

  const handleReply = () => {
    if (!user) return;
    if (isReplying && replyContent.trim()) {
      onReply(comment.id);
      setReplyContent('');
    }
    setIsReplying(!isReplying);
  };

  const handleSubmitReply = (e: React.FormEvent) => {
    e.preventDefault();
    if (replyContent.trim()) {
      onReply(comment.id);
      setReplyContent('');
      setIsReplying(false);
    }
  };

  return (
    <div className={`${level > 0 ? 'ml-8 border-l-2 border-gray-100 pl-4' : ''}`}>
      <div className="flex items-start space-x-3">
        <img
          src={comment.userAvatar || `https://ui-avatars.com/api/?name=${comment.username}&background=random`}
          alt={comment.username}
          className="w-8 h-8 rounded-full"
        />
        <div className="flex-1">
          <div className="bg-gray-50 rounded-lg p-3">
            <div className="flex items-center justify-between mb-1">
              <span className="font-medium">{comment.username}</span>
              <span className="text-sm text-gray-500">{formatDate(comment.createdAt)}</span>
            </div>
            <p className="text-gray-700">{comment.content}</p>
          </div>

          <div className="mt-2">
            <CommentReactions
              comment={comment}
              onReact={handleReact}
              onReply={handleReply}
            />
          </div>

          {isReplying && (
            <form onSubmit={handleSubmitReply} className="mt-3">
              <textarea
                value={replyContent}
                onChange={(e) => setReplyContent(e.target.value)}
                placeholder="返信を入力..."
                className="w-full p-2 border border-gray-200 rounded-lg focus:ring-1 focus:ring-red-500"
                rows={2}
              />
              <div className="mt-2 flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={() => setIsReplying(false)}
                  className="px-3 py-1 text-gray-600 hover:text-gray-800"
                >
                  キャンセル
                </button>
                <button
                  type="submit"
                  className="px-3 py-1 bg-red-600 text-white rounded-lg hover:bg-red-700"
                  disabled={!replyContent.trim()}
                >
                  返信する
                </button>
              </div>
            </form>
          )}
        </div>
      </div>

      {comment.replies && comment.replies.length > 0 && (
        <div className="mt-4 space-y-4">
          {comment.replies.map(reply => (
            <CommentItem
              key={reply.id}
              comment={reply}
              onReact={onReact}
              onReply={onReply}
              level={level + 1}
            />
          ))}
        </div>
      )}
    </div>
  );
}