import React from 'react';
import { useParams } from 'react-router-dom';
import DealCard from './DealCard';
import { useDeals } from '../hooks/useDeals';

export default function CategoryPage() {
  const { category } = useParams<{ category: string }>();
  const { getDealsByCategory } = useDeals();
  const decodedCategory = decodeURIComponent(category || '');
  const deals = getDealsByCategory(decodedCategory);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 py-8">
      <h1 className="text-2xl font-bold mb-6">{decodedCategory}のお得な情報</h1>
      
      {deals.length > 0 ? (
        <div className="grid grid-cols-1 gap-6">
          {deals.map(deal => (
            <DealCard key={deal.id} {...deal} />
          ))}
        </div>
      ) : (
        <div className="text-center py-12">
          <p className="text-gray-500">
            この商品カテゴリーの商品は現在ありません。
          </p>
        </div>
      )}
    </div>
  );
}