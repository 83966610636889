import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { X, AlertCircle, LinkIcon, Tag, DollarSign, ImageIcon, CheckCircle } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import { useAuth } from '../hooks/useAuth';
import { useDeals } from '../hooks/useDeals';
import { ALL_CATEGORIES } from '../data/categories';

interface PostDealModalProps {
  isOpen: boolean;
  onClose: () => void;
}

// ... (previous interfaces and constants remain the same)

export default function PostDealModal({ isOpen, onClose }: PostDealModalProps) {
  if (!isOpen) return null;

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState<FormData>(INITIAL_FORM_DATA);
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const { user } = useAuth();
  const { addDeal, setSortType } = useDeals();
  const navigate = useNavigate();

  // ... (previous functions remain the same until handleSubmit)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');

    if (step === 3 && !validatePrices()) {
      return;
    }

    if (step === 4 && !formData.image) {
      setError('商品画像を選択してください');
      return;
    }

    if (step < 5) {
      setStep(step + 1);
      return;
    }

    try {
      setIsSubmitting(true);
      const priceNum = parseInt(formData.price.replace(/[^\d]/g, ''));
      const originalPriceNum = parseInt(formData.originalPrice.replace(/[^\d]/g, ''));
      const discount = Math.round(((originalPriceNum - priceNum) / originalPriceNum) * 100);

      const newDeal = {
        title: formData.title,
        description: formData.description.slice(0, 1200),
        price: priceNum,
        originalPrice: originalPriceNum,
        discount,
        merchant: formData.merchant,
        url: formData.url,
        category: formData.category,
        image: formData.imagePreview,
        createdAt: new Date().toISOString(),
        username: user?.username || 'ユーザー',
        shippingFee: formData.shippingFee,
        couponCode: formData.couponCode,
        votes: 0,
        comments: 0,
        posted: '数分前'
      };

      const deal = addDeal(newDeal);
      setShowSuccess(true);

      // Show success message for 1.5 seconds
      setTimeout(() => {
        setFormData(INITIAL_FORM_DATA);
        setStep(1);
        setShowSuccess(false);
        onClose();
        
        // Switch to "新着順" tab and navigate to home
        setSortType('new');
        navigate('/', { replace: true });
        
        // Scroll to the newly added deal after a short delay
        setTimeout(() => {
          const dealElement = document.getElementById(`deal-${deal.id}`);
          if (dealElement) {
            dealElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            dealElement.classList.add('highlight-new-deal');
          }
        }, 100);
      }, 1500);
    } catch (err) {
      setError('投稿に失敗しました。もう一度お試しください。');
    } finally {
      setIsSubmitting(false);
    }
  };

  // ... (rest of the component remains the same)
}