import React from 'react';
import { useParams } from 'react-router-dom';
import { Store } from 'lucide-react';
import DealCard from './DealCard';
import type { Deal } from '../types';

export default function SupplierDeals() {
  const { supplier } = useParams<{ supplier: string }>();
  const decodedSupplier = decodeURIComponent(supplier || '');

  // Mock data - in a real app, fetch deals filtered by supplier
  const supplierDeals: (Deal & { username: string })[] = [
    {
      id: '1',
      title: "PlayStation 5 デジタルエディション",
      price: 49980,
      originalPrice: 59980,
      discount: 17,
      image: "https://images.unsplash.com/photo-1606813907291-d86efa9b94db?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80",
      merchant: decodedSupplier,
      votes: 342,
      comments: 56,
      posted: "2時間前",
      url: "#",
      username: "GameDeals"
    },
    {
      id: '2',
      title: "Apple AirPods Pro",
      price: 29800,
      originalPrice: 39800,
      discount: 25,
      image: "https://images.unsplash.com/photo-1588423771073-b8903fbb85b5?ixlib=rb-4.0.3&auto=format&fit=crop&w=1200&q=80",
      merchant: decodedSupplier,
      votes: 528,
      comments: 89,
      posted: "3時間前",
      url: "#",
      username: "TechSaver"
    }
  ];

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 py-8">
      <div className="flex items-center space-x-3 mb-8">
        <Store className="h-6 w-6 text-gray-600" />
        <h1 className="text-2xl font-bold text-gray-900">
          {decodedSupplier}の商品一覧
        </h1>
      </div>

      <div className="grid grid-cols-1 gap-6">
        {supplierDeals.map(deal => (
          <DealCard key={deal.id} {...deal} />
        ))}
      </div>
    </div>
  );
}