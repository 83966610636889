import React, { useState } from 'react';
import { MessageCircle } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import CommentItem from './CommentItem';
import { useComments } from '../hooks/useComments';
import type { ReactionType } from '../types';

interface CommentSectionProps {
  dealId: string;
}

export default function CommentSection({ dealId }: CommentSectionProps) {
  const { user } = useAuth();
  const [newComment, setNewComment] = useState('');
  const { comments, addComment, addReaction, addReply } = useComments(dealId);

  const handleSubmitComment = (e: React.FormEvent) => {
    e.preventDefault();
    if (!user) {
      alert('コメントを投稿するにはログインが必要です。');
      return;
    }
    if (!newComment.trim()) return;

    addComment(newComment);
    setNewComment('');
  };

  const handleReact = (commentId: string, type: ReactionType) => {
    if (!user) {
      alert('リアクションするにはログインが必要です。');
      return;
    }
    addReaction(commentId, type);
  };

  const handleReply = (parentId: string) => {
    if (!user) {
      alert('返信するにはログインが必要です。');
      return;
    }
    addReply(parentId, newComment);
    setNewComment('');
  };

  return (
    <div className="mt-8">
      <h3 className="text-xl font-semibold mb-4 flex items-center">
        <MessageCircle className="h-5 w-5 mr-2" />
        コメント ({comments.length})
      </h3>

      {user ? (
        <form onSubmit={handleSubmitComment} className="mb-6">
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="コメントを投稿..."
            className="w-full p-3 border border-gray-200 rounded-lg focus:ring-1 focus:ring-red-500"
            rows={3}
          />
          <button
            type="submit"
            className="mt-2 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors"
            disabled={!newComment.trim()}
          >
            投稿する
          </button>
        </form>
      ) : (
        <div className="mb-6 p-4 bg-gray-50 rounded-lg text-center">
          <p className="text-gray-600">
            コメントを投稿するには
            <button className="text-red-600 hover:text-red-700 font-semibold mx-1">
              ログイン
            </button>
            してください
          </p>
        </div>
      )}

      <div className="space-y-6">
        {comments.map(comment => (
          <CommentItem
            key={comment.id}
            comment={comment}
            onReact={handleReact}
            onReply={handleReply}
          />
        ))}
      </div>
    </div>
  );
}