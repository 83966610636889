import React from 'react';
import { Shield } from 'lucide-react';

export default function PrivacyPolicyPage() {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center mb-6">
          <Shield className="h-6 w-6 text-red-600 mr-2" />
          <h1 className="text-2xl font-bold">プライバシーポリシー</h1>
        </div>

        <div className="prose max-w-none">
          <p className="text-gray-600 mb-6">
            お得ズ（以下「当サイト」）は、お客様の個人情報保護の重要性について認識し、個人情報の保護に関する法律（以下「個人情報保護法」）に基づき、以下のプライバシーポリシー（以下「本ポリシー」）を定め、これを遵守することをお約束します。
          </p>

          <h2 className="text-xl font-semibold mt-8 mb-4">1. 個人情報の定義</h2>
          <p className="text-gray-600 mb-6">
            本ポリシーにおける「個人情報」とは、個人情報保護法第2条第1項により定義される個人情報を指します。
          </p>

          <h2 className="text-xl font-semibold mt-8 mb-4">2. 収集する個人情報の項目</h2>
          <p className="text-gray-600 mb-4">当サイトは、以下の個人情報を収集する場合があります：</p>
          <ul className="list-disc list-inside space-y-2 text-gray-600 mb-6">
            <li>氏名</li>
            <li>メールアドレス</li>
            <li>パスワード</li>
            <li>プロフィール情報（任意）</li>
            <li>IPアドレス</li>
            <li>Cookie情報</li>
            <li>利用ログ</li>
            <li>その他当サイトが定める入力フォームにお客様が入力する情報</li>
          </ul>

          <h2 className="text-xl font-semibold mt-8 mb-4">3. 個人情報の利用目的</h2>
          <p className="text-gray-600 mb-4">当サイトは、収集した個人情報を以下の目的で利用します：</p>
          <ul className="list-disc list-inside space-y-2 text-gray-600 mb-6">
            <li>ユーザー登録及び認証のため</li>
            <li>サービスの提供・運営のため</li>
            <li>お問い合わせへの対応のため</li>
            <li>利用規約に違反する行為への対応のため</li>
            <li>サービスの改善及び新機能の開発のため</li>
            <li>サービスに関する重要なお知らせの配信のため</li>
            <li>利用状況の分析及び統計データの作成のため</li>
            <li>その他、上記の利用目的に付随する目的のため</li>
          </ul>

          <h2 className="text-xl font-semibold mt-8 mb-4">4. 個人情報の第三者提供</h2>
          <p className="text-gray-600 mb-6">
            当サイトは、以下のいずれかに該当する場合を除き、個人情報を第三者に提供することはありません：
          </p>
          <ul className="list-disc list-inside space-y-2 text-gray-600 mb-6">
            <li>ご本人の同意がある場合</li>
            <li>法令に基づく場合</li>
            <li>人の生命、身体または財産の保護のために必要がある場合</li>
            <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合</li>
            <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合</li>
          </ul>

          <h2 className="text-xl font-semibold mt-8 mb-4">5. 個人情報の安全管理</h2>
          <p className="text-gray-600 mb-6">
            当サイトは、個人情報の漏洩、滅失、き損等を防止するため、必要かつ適切な安全管理措置を講じるとともに、個人情報を取り扱う従業者に対して、個人情報の適切な取り扱いに関する教育を実施します。
          </p>

          <h2 className="text-xl font-semibold mt-8 mb-4">6. Cookieの使用について</h2>
          <p className="text-gray-600 mb-6">
            当サイトは、ユーザーの利便性向上のためCookieを使用することがあります。Cookieの使用を望まない場合は、ブラウザの設定でCookieを無効にすることができます。ただし、一部のサービスが正常に機能しなくなる可能性があります。
          </p>

          <h2 className="text-xl font-semibold mt-8 mb-4">7. 個人情報の開示・訂正・利用停止等</h2>
          <p className="text-gray-600 mb-6">
            当サイトは、ユーザーから個人情報の開示、訂正、利用停止等の請求があった場合、本人確認を行った上で、法令に基づき適切に対応します。
          </p>

          <h2 className="text-xl font-semibold mt-8 mb-4">8. プライバシーポリシーの変更</h2>
          <p className="text-gray-600 mb-6">
            当サイトは、必要に応じて本ポリシーを変更することがあります。変更後のプライバシーポリシーは、当サイトに掲載した時点から効力を生じるものとします。
          </p>

          <h2 className="text-xl font-semibold mt-8 mb-4">9. お問い合わせ窓口</h2>
          <p className="text-gray-600 mb-6">
            本ポリシーに関するお問い合わせは、以下の窓口までお願いいたします：
          </p>
          <div className="bg-gray-50 p-4 rounded-lg">
            <p className="text-gray-600">
              お得ズ 個人情報保護担当<br />
              メール：privacy@otokuzu.jp
            </p>
          </div>

          <p className="text-gray-500 mt-8 text-sm">
            制定日：2024年1月1日<br />
            最終更新日：2024年1月1日
          </p>
        </div>
      </div>
    </div>
  );
}