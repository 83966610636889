import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Tag, MessageCircle, ExternalLink, Share2, Heart } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';
import { useFavorites } from '../hooks/useFavorites';
import VoteButtons from './VoteButtons';
import RelatedDeals from './RelatedDeals';
import { useDeals } from '../hooks/useDeals';
import { formatDate } from '../utils/dateUtils';
import CommentSection from './CommentSection';
import AuthModal from './AuthModal';

export default function DealPage() {
  const { id } = useParams();
  const { getDealById, deals } = useDeals();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { isFavorite, toggleFavorite } = useFavorites();
  const [showAuthModal, setShowAuthModal] = React.useState(false);
  const deal = getDealById(id || '');

  const handleFavoriteClick = () => {
    if (!user) {
      setShowAuthModal(true);
      return;
    }
    toggleFavorite(id || '');
  };

  if (!deal) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-16 text-center">
        <h2 className="text-2xl font-bold text-gray-900 mb-4">
          商品が見つかりませんでした
        </h2>
        <p className="text-gray-600 mb-8">
          お探しの商品は削除されたか、URLが間違っている可能性があります。
        </p>
        <button
          onClick={() => navigate('/')}
          className="px-6 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
        >
          トップページへ戻る
        </button>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-sm border border-gray-100 overflow-hidden">
        <div className="p-6">
          <div className="flex flex-col md:flex-row gap-6">
            {/* Left column with image and voting */}
            <div className="w-full md:w-1/3">
              <div className="relative">
                <img 
                  src={deal.image} 
                  alt={deal.title} 
                  className="w-full h-64 object-cover rounded-lg"
                />
                <button
                  onClick={handleFavoriteClick}
                  className={`absolute top-2 right-2 p-2 rounded-full bg-white shadow-md transition-colors ${
                    isFavorite(deal.id)
                      ? 'text-red-600 hover:text-red-700'
                      : 'text-gray-400 hover:text-red-600'
                  }`}
                >
                  <Heart
                    className={`h-5 w-5 ${isFavorite(deal.id) ? 'fill-current' : ''}`}
                  />
                </button>
              </div>
              <div className="mt-4 flex justify-center">
                <VoteButtons dealId={deal.id} initialScore={deal.votes} size="lg" />
              </div>
            </div>

            {/* Right column with deal details */}
            <div className="flex-1">
              <div className="flex items-center justify-between mb-4">
                <span className="text-sm text-gray-500">{formatDate(deal.posted)}</span>
                <div className="flex items-center gap-2">
                  <button className="p-2 hover:bg-gray-100 rounded-full">
                    <Share2 className="h-5 w-5 text-gray-600" />
                  </button>
                  <button className="p-2 hover:bg-gray-100 rounded-full">
                    <Tag className="h-5 w-5 text-gray-600" />
                  </button>
                </div>
              </div>

              <h1 className="text-2xl font-bold mb-4">{deal.title}</h1>

              <div className="flex items-baseline gap-3 mb-4">
                <span className="text-3xl font-bold text-red-600">¥{deal.price?.toLocaleString()}</span>
                {deal.originalPrice && (
                  <>
                    <span className="text-lg text-gray-400 line-through">¥{deal.originalPrice?.toLocaleString()}</span>
                    <span className="text-sm font-semibold bg-red-100 text-red-600 px-2 py-1 rounded">
                      {deal.discount}% OFF
                    </span>
                  </>
                )}
              </div>

              <a 
                href={deal.merchant} 
                className="text-sm text-gray-600 hover:text-red-600 mb-6 block"
              >
                {deal.merchant}
              </a>

              <div className="flex gap-4 mb-6">
                <a
                  href={deal.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex-1 bg-red-600 text-white text-center py-3 rounded-lg hover:bg-red-700 transition-colors"
                >
                  商品サイトへ
                  <ExternalLink className="inline-block ml-2 h-4 w-4" />
                </a>
              </div>

              <div className="prose max-w-none">
                <h2 className="text-lg font-semibold mb-2">商品について</h2>
                <p className="text-gray-700 whitespace-pre-wrap">{deal.description}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-100 p-6">
          <CommentSection dealId={deal.id} />
        </div>
      </div>

      <RelatedDeals currentDeal={deal} allDeals={deals} />

      <AuthModal 
        isOpen={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        message="お気に入りに追加するにはログインが必要です。会員登録（無料）もしくはログインして、お気に入り機能をご利用ください。"
      />
    </div>
  );
}