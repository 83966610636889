import React from 'react';
import { Link } from 'react-router-dom';
import { Tag } from 'lucide-react';
import { ALL_CATEGORIES } from '../data/categories';

export default function CategoriesPage() {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 py-8">
      <h1 className="text-2xl font-bold mb-6">カテゴリー一覧</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {ALL_CATEGORIES.map((category) => (
          <Link
            key={category.id}
            to={`/category/${encodeURIComponent(category.name)}`}
            className="block bg-white rounded-lg border border-gray-200 p-6 hover:shadow-md transition-shadow"
          >
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center">
                <Tag className="h-5 w-5 text-red-600 mr-2" />
                <h2 className="text-lg font-semibold">{category.name}</h2>
              </div>
              <span className="text-sm text-gray-500">{category.count || 0}件</span>
            </div>
            <p className="text-gray-600 text-sm">{category.description}</p>
          </Link>
        ))}
      </div>
    </div>
  );
}