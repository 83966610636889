import React from 'react';
import { ScrollText } from 'lucide-react';

export default function TermsPage() {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="flex items-center mb-6">
          <ScrollText className="h-6 w-6 text-red-600 mr-2" />
          <h1 className="text-2xl font-bold">利用規約</h1>
        </div>

        <div className="prose max-w-none">
          <p className="text-gray-600 mb-6">
            この利用規約（以下「本規約」）は、お得ズ（以下「当サイト」）が提供するウェブサイト「お得ズ」（以下「本サービス」）の利用条件を定めるものです。
            登録ユーザーの皆様（以下「ユーザー」）には、本規約に従って本サービスをご利用いただきます。
          </p>

          <h2 className="text-xl font-semibold mt-8 mb-4">第1条（適用）</h2>
          <ol className="list-decimal list-inside space-y-2 text-gray-600 mb-6">
            <li>本規約は、ユーザーと当サイトとの間の本サービスの利用に関わる一切の関係に適用されるものとします。</li>
            <li>当サイトは本サービスに関し、本規約のほか、ご利用にあたってのルール等、各種の定め（以下、「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず、本規約の一部を構成するものとします。</li>
            <li>本規約の規定が前条の個別規定の規定と矛盾する場合には、個別規定において特段の定めなき限り、個別規定の規定が優先されるものとします。</li>
          </ol>

          <h2 className="text-xl font-semibold mt-8 mb-4">第2条（利用登録）</h2>
          <ol className="list-decimal list-inside space-y-2 text-gray-600 mb-6">
            <li>本サービスにおいては、登録希望者が本規約に同意の上、当サイトの定める方法によって利用登録を申請し、当サイトがこれを承認することによって、利用登録が完了するものとします。</li>
            <li>当サイトは、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
              <ul className="list-disc list-inside ml-6 space-y-1">
                <li>利用登録の申請に際して虚偽の事項を届け出た場合</li>
                <li>本規約に違反したことがある者からの申請である場合</li>
                <li>その他、当サイトが利用登録を相当でないと判断した場合</li>
              </ul>
            </li>
          </ol>

          <h2 className="text-xl font-semibold mt-8 mb-4">第3条（ユーザーIDおよびパスワードの管理）</h2>
          <ol className="list-decimal list-inside space-y-2 text-gray-600 mb-6">
            <li>ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを適切に管理するものとします。</li>
            <li>ユーザーは、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与し、もしくは第三者と共用することはできません。当サイトは、ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録しているユーザー自身による利用とみなします。</li>
            <li>ユーザーID及びパスワードが第三者によって使用されたことによって生じた損害は、当サイトは一切の責任を負わないものとします。</li>
          </ol>

          <h2 className="text-xl font-semibold mt-8 mb-4">第4条（禁止事項）</h2>
          <p className="text-gray-600 mb-4">ユーザーは、本サービスの利用にあたり、以下の行為をしてはなりません。</p>
          <ol className="list-decimal list-inside space-y-2 text-gray-600 mb-6">
            <li>法令または公序良俗に違反する行為</li>
            <li>犯罪行為に関連する行為</li>
            <li>当サイト、本サービスの他のユーザー、または第三者のサーバーまたはネットワークの機能を破壊したり、妨害したりする行為</li>
            <li>当サイトのサービスの運営を妨害するおそれのある行為</li>
            <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
            <li>不正アクセスをし、またはこれを試みる行為</li>
            <li>他のユーザーに成りすます行為</li>
            <li>当サイトのサービスに関連して、反社会的勢力に対して直接または間接に利益を供与する行為</li>
            <li>当サイト、本サービスの他のユーザーまたは第三者の知的財産権、肖像権、プライバシー、名誉その他の権利または利益を侵害する行為</li>
            <li>以下の表現を含み、または含むと当サイトが判断する内容を投稿する行為
              <ul className="list-disc list-inside ml-6 space-y-1">
                <li>過度に暴力的な表現</li>
                <li>露骨な性的表現</li>
                <li>人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現</li>
                <li>自殺、自傷行為、薬物乱用を誘引または助長する表現</li>
                <li>その他反社会的な内容を含み他人に不快感を与える表現</li>
              </ul>
            </li>
            <li>複数のアカウントを所持する行為</li>
            <li>アフィリエイトリンクの掲載、自社製品・サービスの宣伝、フリマサイトからの転売品の出品</li>
            <li>その他、当サイトが不適切と判断する行為</li>
          </ol>

          <h2 className="text-xl font-semibold mt-8 mb-4">第5条（本サービスの提供の停止等）</h2>
          <ol className="list-decimal list-inside space-y-2 text-gray-600 mb-6">
            <li>当サイトは、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
              <ul className="list-disc list-inside ml-6 space-y-1">
                <li>本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</li>
                <li>地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合</li>
                <li>コンピュータまたは通信回線等が事故により停止した場合</li>
                <li>その他、当サイトが本サービスの提供が困難と判断した場合</li>
              </ul>
            </li>
            <li>当サイトは、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害についても、一切の責任を負わないものとします。</li>
          </ol>

          <h2 className="text-xl font-semibold mt-8 mb-4">第6条（著作権）</h2>
          <ol className="list-decimal list-inside space-y-2 text-gray-600 mb-6">
            <li>ユーザーは、自ら著作権等の必要な知的財産権を有するか、または必要な権利者の許諾を得た文章、画像や映像等の情報に関してのみ、本サービスを利用し、投稿ないしアップロードすることができるものとします。</li>
            <li>ユーザーが本サービスを利用して投稿ないしアップロードした文章、画像、映像等の著作権については、当該ユーザーその他既存の権利者に留保されるものとします。ただし、当サイトは、本サービスを利用して投稿ないしアップロードされた文章、画像、映像等について、本サービスの改良、品質の向上、または不備の是正等ならびに本サービスの周知宣伝等に必要な範囲で利用できるものとし、ユーザーは、この利用に関して、著作者人格権を行使しないものとします。</li>
            <li>前項本文の定めるものを除き、本サービスおよび本サービスに関連する一切の情報についての著作権およびその他の知的財産権はすべて当サイトまたは当サイトにその利用を許諾した権利者に帰属し、ユーザーは無断で複製、譲渡、貸与、翻訳、改変、転載、公衆送信（送信可能化を含みます。）、伝送、配布、出版、営業使用等をしてはならないものとします。</li>
          </ol>

          <h2 className="text-xl font-semibold mt-8 mb-4">第7条（利用制限および登録抹消）</h2>
          <ol className="list-decimal list-inside space-y-2 text-gray-600 mb-6">
            <li>当サイトは、ユーザーが以下のいずれかに該当する場合には、事前の通知なく、投稿データを削除し、ユーザーに対して本サービスの全部もしくは一部の利用を制限しまたはユーザーとしての登録を抹消することができるものとします。
              <ul className="list-disc list-inside ml-6 space-y-1">
                <li>本規約のいずれかの条項に違反した場合</li>
                <li>登録事項に虚偽の事実があることが判明した場合</li>
                <li>当サイトからの連絡に対し、一定期間返答がない場合</li>
                <li>本サービスについて、最終の利用から一定期間利用がない場合</li>
                <li>その他、当サイトが本サービスの利用を適当でないと判断した場合</li>
              </ul>
            </li>
            <li>前項各号のいずれかに該当した場合、ユーザーは、当然に当サイトに対する一切の債務について期限の利益を失い、その時点において負担する一切の債務を直ちに一括して弁済しなければなりません。</li>
            <li>当サイトは、本条に基づき当サイトが行った行為によりユーザーに生じた損害について、一切の責任を負いません。</li>
          </ol>

          <h2 className="text-xl font-semibold mt-8 mb-4">第8条（退会）</h2>
          <p className="text-gray-600 mb-6">
            ユーザーは、当サイトの定める退会手続により、本サービスから退会できるものとします。
          </p>

          <h2 className="text-xl font-semibold mt-8 mb-4">第9条（保証の否認および免責事項）</h2>
          <ol className="list-decimal list-inside space-y-2 text-gray-600 mb-6">
            <li>当サイトは、本サービスに事実上または法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。</li>
            <li>当サイトは、本サービスに起因してユーザーに生じたあらゆる損害について、当サイトの故意又は重過失による場合を除き、一切の責任を負いません。</li>
            <li>当サイトは、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。</li>
          </ol>

          <h2 className="text-xl font-semibold mt-8 mb-4">第10条（準拠法・裁判管轄）</h2>
          <ol className="list-decimal list-inside space-y-2 text-gray-600 mb-6">
            <li>本規約の解釈にあたっては、日本法を準拠法とします。</li>
            <li>本サービスに関して紛争が生じた場合には、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</li>
          </ol>

          <p className="text-gray-500 mt-8 text-sm">
            制定日：2024年1月1日<br />
            最終更新日：2024年1月1日
          </p>
        </div>
      </div>
    </div>
  );
}